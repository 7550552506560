import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/IT/layout'

import pic11 from '../../assets/imgs/landing/p1.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> OBIETTIVI </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    
                    <h3> OBIETTIVI GENERALI </h3>
                        <ul>
                            <li> Comprendere la diversità dei limiti umani in relazione al movimento
e al proprio corpo</li> 
                            <li>  Sviluppare programmi e servizi per tutte le categorie di persone con
e senza disabilità</li>
                            <li> Creare un forte collegamento tra sport e settori impegnati
nell’inclusione sociale e accrescere l’interesse verso gli sport tra
persone fisicamente inattive </li>
                            <li>Massimizzare il contributo dello sport per raggiungere gli obiettivi
della stretegia Europa 2020 per una crescita intelligente, sostenibile
e inclusiva.</li> 
                            
                        
                        </ul>

                        <h3> OBIETTIVI SPECIFICI
 </h3>
        <ul>


            <li>Comprendere e integrare i giovani con e senza disabilità nei
programmi educativi di danza</li>
            <li> Sviluppare opportunità nel campo della danza per le persone con
disabilità</li>
            <li> Facilitare lo scambio di conoscenze e buone pratiche nel campo
della danza e dello sport integrato tra le parti interessate </li>
            <li> Facilitare il dialogo interculturale con l’inclusione delle persone
di diversi background sociali attraverso lo sport nei tre Paesi
partner. </li>
        </ul>

                </div>
            </section>
        </div>

    </Layout>
)

export default Generic